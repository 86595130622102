import 'bootstrap/js/dist/modal.js';
import 'bootstrap/js/dist/tab.js';

$(document).ready(() => {
    var $btnMoreInformation = $('.gtcookies-more-information');
    var $gtcookiesModal = $('#gtcookies-modal');

    $('body').on('click', '.gtcookies-more-information', function(e) {
        $gtcookiesModal.toggleClass('open');
        e.preventDefault();
    });
});